import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import post from "@/utils/ajax.js";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "../scss/custom.scss";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import filters from "./utils/filter"; // global filters
import MetaInfo from 'vue-meta-info'
import { i18n } from './i18n/index'

// register global utility filters
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
dayjs.extend(customParseFormat);

Vue.config.productionTip = false;
Vue.prototype.$post = post;
Vue.prototype.$dayjs = dayjs;

window.$dayjs = dayjs;

const DEFAULT_TITLE = "Best Car Rental | Cheap Car Hire New Zealand | Top Rental";

router.beforeEach((to, from, next) => {
    let token = sessionStorage.getItem("token");
    if (!token) {
        let expireDate = localStorage.getItem("token-expire-date");
        let globalToken = localStorage.getItem("token");
        let now = new Date().getTime();
        if (!!expireDate && expireDate > now && !!globalToken) {
            token = globalToken;
            sessionStorage.setItem("token", token);
        }
    }
    if (to.meta.admin && !token) {
        let fa;
        if (to.path != "/login") {
            fa = to.path;
        } else {
            fa = from.path;
        }
        next({ path: "/login?fa=" + fa });
    } else {
        next();
    }
});
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
        window.scrollTo(0, 0);
    });
});

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(MetaInfo)


const messages = {
    en: {
      message: {
        hello: 'hello world'
      }
    }
  }

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
