<template>
  <div id="app">
    <div id="nav" class="middle">
      <navbar></navbar>
    </div>
    <div style="min-height: 70vh;">
      <router-view />
    </div>

    <b-card no-body class="border-0 mt-5 middle d-md-block d-none" id="footer">
      <b-row class="bg-blue rounded" no-gutters align-h="between">
        <b-col cols="3">
          <b-img fluid src="./assets/logo-bottom.jpg" class="mt-4 pl-4"></b-img>
          <b-row no-gutters class="ml-5 mt-4 pt-2">
            <b-col>
              <i class="tt-icon ins h2 hoverpointer-footer"></i>
            </b-col>
            <b-col>
              <i class="tt-icon facebook h2 hoverpointer-footer"></i>
            </b-col>
            <b-col>
              <b-img src="@/assets/contact/whatsapp.jpg" v-show="showQR1" class="pop-out"></b-img>
              <i @mouseover="showQR1= true" @mouseout="showQR1 = false" class="tt-icon whatsapp h2 hoverpointer-footer"></i>
            </b-col>
            <b-col>
              <b-img src="@/assets/contact/wechat.jpg" v-show="showQR2" class="pop-out"></b-img>
              <i @mouseover="showQR2= true" @mouseout="showQR2 = false" class="tt-icon wechat h2 hoverpointer-footer"></i>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="py-4 col-3 col-lg-2 offset-2">
          <b-row @click="goto('/contact')" class="p-1 hoverpointer">{{ $t("AboutUs") }}</b-row>
          <b-row @click="goto('/cars/all')" class="p-1 hoverpointer">{{ $t("Vehicles") }}</b-row>
          <b-row @click="goto('/shuttle')" class="p-1 hoverpointer">{{ $t("ShuttleBus") }}</b-row>
          <b-row @click="goto('/terms')" class="p-1 hoverpointer">{{ $t("TermsConditions") }}</b-row>
        </b-col>
        <b-col class="py-4 col-2">
          <b-row @click="goto('/hotdeals')" class="p-1 hoverpointer">{{ $t("HotDeals") }}</b-row>
          <b-row @click="goto('/manage')" class="p-1 hoverpointer">{{ $t("Managebooking") }}</b-row>
          <b-row @click="goto('/pickup')" class="p-1 hoverpointer">{{ $t("Collectyourcar") }}</b-row>
          <b-row @click="goto('/dropoff')" class="p-1 hoverpointer">{{ $t("Returnyourcar") }}</b-row>
        </b-col>
        <b-col class="py-4 col-2">
          <b-row @click="goto('/contact',1)" class="p-1 hoverpointer">{{ $t("Contactus") }}</b-row>
          <b-row @click="goto('/auckland')" class="p-1 hoverpointer">{{ $t("Location") }}</b-row>
          <b-row @click="goto('/support')" class="p-1 hoverpointer">{{ $t("Support") }}</b-row>
          <b-row class="p-1 hoverpointer">{{ $t("Partners") }}</b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          Copyright © 2022 Top Rental |
          <a @click="goto('/terms')" class="hoverpointer">Terms & Conditions</a> | Website by
          <a href="https://dweb.co.nz/" style="color:black;">Damon</a>
        </b-col>
      </b-row>
    </b-card>
    <!-- <cookies></cookies> -->
  </div>
</template>

<script>
import navbar from "@/components/navbar.vue";
// import cookies from "@/components/cookies.vue"; (FOR Hiding Cookies temporarily)
export default {
    data() {
        return { showQR1: false, showQR2: false };
    },
    components: {
        navbar,
        // cookies,
    },
    methods: {
        goto(route) {
            this.$router.push(route);
        },
        goto(route, pos) {
            this.$router.push({ path: route, query: { pos: pos } });
        },
    },
};
</script>

<style lang="scss">
@media print {
    #footer {
        display: none !important;
    }
    @page {
        margin: 2cm;
    }
}
.middle {
    max-width: 80rem;
    margin: 0 auto 0;
}
.hover-universal {
    color: #2ea9df;
}
.hover-universal:hover {
    cursor: pointer;
    color: skyblue;
}
.hoverpointer:hover {
    cursor: pointer;
}
.hoverpointer-footer:hover {
    cursor: pointer;
    color: rgb(0, 183, 255);
}
.car-title {
    font-family: "Fredoka One", cursive;
    color: rgb(28, 168, 222);
}

.fredoka {
    font-family: "Fredoka One", cursive;
}

.bg-blue {
    background-color: rgb(1, 159, 207);
    color: white;
}
// The css for Collect your car & On your journey & Return your car
.tips-heading {
    background: #2ea9df;
    margin: 0%;
    color: white;
    padding: 0.5rem 1rem;
}
.ques-header:hover {
    cursor: pointer;
    color: #2ea9df;
}
.answer {
    font-size: 1.2rem;
    padding-left: 1rem;
}

.q-line {
    margin: 0.2rem 0;
    margin-left: -1rem;
}
.pop-out {
    position: absolute;
    width: 200px;
    left: -5rem;
    bottom: 3rem;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 4px;
    z-index: 100;
}
</style>
