/* 
{{ $t("") }}
export const  = "";
*/
export const Vehicles = "Vehicles";
export const HotDeals = "Hot Deals";
export const OpenHours = "Open Hours"; 
export const Importantupdate = "Important update: For shuttle pick up from international airport, the new location can be found by clicking";
export const Here = "Here";
export const AboutUs = "About Us";
export const ShuttleBus= "Shuttle Bus";
export const TermsConditions = "Terms & Conditions";
export const Managebooking = "Manage booking";
export const Collectyourcar = "Collect your car";
export const Returnyourcar = "Return your car";
export const Contactus = "Contact us";
export const Contact = "Contact";
export const Location = "Location";
export const Support = "Support";
export const Partners = "Partners";
export const HotVehicles = "Hot Vehicles";
export const Pickup = "Pick up";
export const Return = "Return";
export const Freecancellation = "Free cancellation";
export const Freeairportshuttle = "Free airport shuttle";
export const Bestpriceguarantee = "Best price guarantee";
export const Nohiddenfees = "No hidden fees";
export const Findmycar = "Find my car";
export const Managemybooking = "Manage my booking";
export const ContactDetails = "Contact Details";
export const OpeningHours = "Opening Hours";
export const MoreOptions = "More Options";
export const NZ = "NZ";
export const AU = "AU";
export const Email = "Email";
export const Address = "Address";
export const Monday = "Monday";
export const Sunday = "Sunday";
export const To = "to";
export const HotDealswith = "Hot Deals with";
export const TopRental = "Top Rental"; 
export const Bookyourrental = "Book your rental car by using the promotional code noted on each deal below to enjoy the discounts.";
export const Ifneedhelp = "if need help.";
export const Newtous = "New to us";
export const Warmwelcometo = "Warm welcome to new customers. Enjoy 5% discount by using promo code";
export const Forallvehicle = "for all vehicle groups (3 days or more).";
export const Rentforaweek = "Rent for a week or more";
export const Usethepromo = "Use the promo code";
export const Andget10 = "and get 10% off on rental rates when you hire a car for a week or more. It is valid for all vehicle groups.";
export const Rentforamonth = "Rent for a month or more";
export const Andget15 = "and get 15% off on rental rates when you hire a month or more. (Over 28 days) It is valid for all vehicle groups.";
export const Forourlovely = "For our lovely returning customer";
export const Contactuswith = "Contact us with your previous booking details to get the 20% off promo code. Exclusive offer for returning customer only. It is valid for all vehicle groups (Minimum 3 days).";
export const WeChat = "WeChat";
export const Supportand = "Support & Services with";
export const Hereisall = "Here is all the helpful information you can find out.";
export const KiaOraand = "Kia Ora and Welcome to Top Rental";
export const Travelingisnever = "Traveling is never easy. This is why we are here to be part of your journey. Enjoying safe and pleasant driving is our";
export const Priority = "priority";
export const Plus = "Plus";
export const Fantasticcarrental = "fantastic car rental experience with";
export const Competitiveprice = "competitive price.";
export const OurCars = "Our Cars";
export const Allourrental = "All our rental vehicles are assessed by New Zealand certified agencies and maintained regularly to provide reliable protection for your journey.";
export const Alsoweprovide = "Also, we provide customers with a variety fleet of options, whether you need an affordable small car for family holiday or people mover for business travel.";
export const OurTeam = "Our Team";
export const Ourprofessionaland = "Our professional and passionate team will help you with the entire process from check in, rental period to drop off. Let make easy to all our customer.";
export const Letusto = "Let us to help you remove the cost of car rental from your important expenses in travel list.";
export const Usetheonline = "Use the online enquiry form below to send us a message and we will be in touch within 24 hours.";
export const Submit = "Submit";
export const Moredetails = "More details";
export const MonSun = "Mon-Sun: 9am-6pm";
export const Top = "Top";
export const Weprovidefree = "We provide free shuttle service between our depot and airport during open hours, from 9am–6pm, Monday to Sunday.";
export const Calluson = "Call us on";
export const Or = "or";
export const Whenyouare = "when you are ready for pick up.";
export const ForAucklandInternational = "For Auckland International Terminal picking up:";
export const Pleaseexitthrough = "Please exit through Door 4, across the pedestrian crossing, then walk into footpath marked as 'Car Rental'. Follow 'car rental/shuttle' sign, and yellow arrowhead on photos will lead you to shuttle picking up point.";
export const ForAucklandDomestic = "For Auckland Domestic Terminal picking up:";
export const Pleasewaitoutside = "Please wait outside of door 8 (outside of Jetstar counter), shuttlebus will stop in front of you within red line marked area.";
export const ReservationNumber = "Reservation Number";
export const LastName = "Last Name";
export const Search = "Search";
export const ManageYourBooking = "Manage Your Booking";
export const FindMyBooking = "Find My Booking";
export const Booking = "Booking";
export const Yourbookinghas = "Your booking has been";
export const Contactusif = ". Contact us if you need help.";
export const Yourdetailsare = "Your details are shown below.";
export const Name = "Name:";
export const DOB = "DOB:";
export const Phone = "Phone";
export const Flight = "Flight";
export const Message = "Message";
export const Day = "/day";
export const For = "For";
export const Days = "days:";
export const BookingDetails = "Booking Details";
export const PickUp = "Pick Up";
export const CarRentalFee = "Car Rental Fee";
export const Discount = "Discount";
export const TOTAL = "TOTAL";
export const DaysGSTincluded = "days GST included";
export const CANCEL = "CANCEL";
export const NissanTiidaHatch = "Nissan Tiida Hatch";
export const ToyotaCorollaHatch = "Toyota Corolla Hatch";
export const NissanXtrail = "Nissan Xtrail";
export const ToyotaRav4 = "Toyota Rav4";
export const ToyotaHighlander = "Toyota Highlander";
export const NissanPathfinder = "Nissan Pathfinder";
export const ToyotaCamry = "Toyota Camry";
export const ToyotaHighlanderHybrid = "Toyota Highlander Hybrid";
export const ToyotaRav4Hybrid = "Toyota Rav4 Hybrid";
export const ToyotaAquaHybrid = "Toyota Aqua Hybrid";
export const ToyotaCorollaCross = "Toyota Corolla Cross";
export const NissanNote = "Nissan Note";
export const Find = "find";
export const Select = "Select";
export const SoldOut = "Sold Out";
export const Pickyourcar = "Pick your car";
export const Insuranceextras = "Insurance & extras";
export const Driversdetails = "Driver's details";
export const SecondDriver = "Second Driver";
export const FREE = "FREE";
export const Max140 = "max:140";
export const Iagreeto = "I agree to the";
export const BookingSummary = "Booking Summary";
export const Howtopay = "How to pay";
export const Back = "Back";
export const BookNow = "Book Now";
export const BookingReceived = "Booking Received";
export const Yourbookinghasbeenreceived = "Your booking has been received and will be confirmed shortly. No payment require until you pick up the car.";
export const Youwillreceive = "You will receive a confirmation email in the next 24 hours. Please check your promotion or spam folder if you cannot see it.";
export const BackHome = "Back Home";
export const Continue = "Continue";
export const Soldout = "Soldout";
export const Promocode = "Promo code";
export const Apply = "Apply";
export const Extraoptions = "Extra options";
export const InsuranceComparison = "Insurance Comparison";
export const SelectYourInsurance = "Select Your Insurance";
export const Vehicleyouchosen = "Vehicle you chosen below";
export const Free = "Free";
export const FirstName = "First Name";
export const EnterFirstName = "Enter first Name";
export const EnterLastName = "Enter last Name";
export const EmailAddress = "Email Address";
export const EnterEmail = "Enter email";
export const PhoneNumber = "Phone Number";
export const EnterPhoneNumber = "Enter phone number";
export const FlightNumber = "Flight Number (Optional)";
export const EnterFlightNumber = "Enter flight Number";
export const DriverBirthdate = "Driver Birthdate";
export const EnterYourMessage = "Enter your message if needed";
export const FullName = "Name";
export const EnterFullName = "Enter Name";
export const submitted = "submitted";
export const confirmed = "confirmed";
export const Basic = "Basic";
export const basic = "Basic";
export const plus = "Protection";
export const canceled = "canceled";
export const premium = "Premium";
export const PhoneHolder = "Phone Holder";
export const GPSNavigation = "GPS Navigation";
export const BoosterSeat = "Booster Seat";
export const BabySeat = "Baby Seat";
export const YoungDriverFee = "Young Driver Fee";
export const Protection = "Protection";
export const Premium = "Premium";
export const Automatic = "Automatic";
export const orsimilar = "or similar";
export const Seats7 = "7 Seats";
export const AutomaticHighBeam = "Automatic High Beam";
export const Bluetooth = "Bluetooth";
export const ReversingCamera = "Reversing Camera";
export const CruiseControl = "Cruise Control";
export const USBPort = "USB Port";
export const LaneDepartureAlert = "Lane Departure Alert";
export const BlindSpotMirror = "Blind Spot Mirror";
export const AdaptiveCruiseControl = "Adaptive Cruise Control";
export const MostPopular = "Most Popular";
export const EcoFriendly = "Eco Friendly";
export const UnlimitedKilometers = "Unlimited Kilometers";
export const Rangeupto800kms = "Range up to 800kms";
export const Rangeupto950kms = "Range up to 950kms";
export const Y04YearsOld = "0 - 4 Years Old";
export const Y03YearsOld = "0 - 3 Years Old";
export const Y02YearsOld = "0 - 2 Years Old";
export const Large7Seats = "Large 7 Seats";
export const AllNewHybridModel = "All New Hybrid Model";
export const opentime = "9am-6pm";
export const Menu = "Menu";









