import axios from "axios";
import Qs from "qs";
axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "" : "/api"; // 请求的默认域名
// 添加一个请求拦截器
axios.interceptors.request.use(
    (config) => {
        // config.headers.languagetype = 'CN'; //加上一个公共头部
        let language = localStorage.getItem("locale") || "en";
        config.data = Qs.stringify({ ...config.data, _language: language }); // 处理数据，可不写
        let token = sessionStorage.getItem("token");
        config.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: (token && "Bearer " + token) || "",
        };
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
//添加一个响应拦截器
axios.interceptors.response.use(
    (res) => {
        //在这里对返回的数据进行处理
        if (res.data.status != 200) {
            return Promise.reject(res.data);
        }
        return res.data;
    },
    (err) => {
        return Promise.reject(err);
    }
);

function post(url, data) {
    return Promise.resolve(
        axios
            .post(url, data)
            .then((resp) => {
                if (!resp || !resp.status || resp.status !== 200) {
                    return Promise.reject(resp);
                } else {
                    return Promise.resolve(resp);
                }
            })
            .catch((e) => {
                return Promise.reject(e);
            })
    );
}
export default post;
