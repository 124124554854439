<template>
  <div>
    <div class="text-center bg-warning font-weight-bold">
      {{ $t("Importantupdate") }}
      <span class="hover-universal" @click="goInfo">{{ $t("Here") }}</span>.
    </div>

    <b-row class="navbar pb-0">
      <b-col class="logo col-sm-3 col-5 hoverpointer" @click="goHome"></b-col>

      <b-col>
        <div class="icon d-md-none text-right">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret style="padding-bottom: 1rem;">
            <template #button-content>
              <i class="iconfont" v-if="language === 'en'">&#xe632;EN</i>
              <i class="iconfont" v-else>&#xe604;中文</i>
            </template>
            <b-dropdown-item href="#" @click="changeLang('zh')">
              <i class="iconfont">&#xe604;</i>中文
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="changeLang('en')">
              <i class="iconfont">&#xe632;</i>EN
            </b-dropdown-item>
          </b-dropdown>
          <i class="tt-icon col-lg-2 col-md-3 col-4 h1" :class="showContactList ? 'chahao' : 'dianhua'" @click="showContactList = !showContactList"></i>

          <i class="tt-icon text-info h1" :class="showNavList ? 'chahao' : 'lanmu'" @click="showNavList = !showNavList"></i>
        </div>
        <div class="d-md-block d-none">
          <div class="contact text-right" v-if="language === 'en'">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="iconfont" v-if="language === 'en'">&#xe632;EN</i>
                <i class="iconfont" v-else>&#xe604;中文</i>
              </template>
              <b-dropdown-item href="#" @click="changeLang('zh')">
                <i class="iconfont">&#xe604;</i>中文
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="changeLang('en')">
                <i class="iconfont">&#xe632;</i>EN
              </b-dropdown-item>
            </b-dropdown>
            <span>
              <span class="text-info">{{ $t("OpenHours") }}</span>
              {{ $t("opentime") }}
            </span>
            <span>
              <span class="text-info">NZ</span>
              <a href="tel:0800 668 628">0800 668 628</a>
            </span>
            <span class="m-0">
              <span class="text-info">AU</span>
              <a href="tel:1800 861 966">1800 861 966</a>
            </span>
          </div>
          <div class="contact text-right" v-else>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="iconfont" v-if="language === 'en'">&#xe632;EN</i>
                <i class="iconfont" v-else>&#xe604;中文</i>
              </template>
              <b-dropdown-item href="#" @click="changeLang('zh')">
                <i class="iconfont">&#xe604;</i>中文
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="changeLang('en')">
                <i class="iconfont">&#xe632;</i>EN
              </b-dropdown-item>
            </b-dropdown>
            <span>
              <span class="text-info">{{ $t("OpenHours") }}</span>
              {{ $t("opentime") }}
            </span>
            <span>
              <i class="tt-icon freephone text-info h4"></i> +64 276682628
            </span>
            <span class="m-0">
              <i class="tt-icon wechat text-info h4"></i> toprentalnz
            </span>
          </div>

          <b-row no-gutters align-h="end" class="mt-1">
            <b-col cols="1"></b-col>
            <b-col v-for="nav in navList" :key="nav.name" class="font-weight-bold text-right" variant="text-white">
              <span class="text-info font-weight-bold h4 d-none d-xl-inline hoverpointer-nav" @click="goto(nav.router)">{{ $t(nav.name) }}</span>
              <span class="text-info font-weight-bold h5 d-none d-lg-inline d-xl-none hoverpointer-nav" @click="goto(nav.router)">{{ $t(nav.name)}}</span>
              <span class="text-info font-weight-bold h6 d-inline d-lg-none hoverpointer-nav" @click="goto(nav.router)">{{ $t(nav.name) }}</span>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-modal size="sm" centeredd button-size="sm" :hide-footer="true" id="modal-1" title="Contact" v-model="showContactList">
      <b-badge variant="info">NZ</b-badge>
      <p>
        <a href="tel:0800 668 628">0800 668 628</a>
      </p>
      <b-badge variant="info">AU</b-badge>
      <p>
        <a href="tel:1800 861 966">1800 861 966</a>
      </p>
      <b-badge variant="info">{{ $t("OpenHours") }}</b-badge>
      <p>Monday - Sunday : 9am-6pm</p>
    </b-modal>
    <b-sidebar backdrop id="sidebar-right" :title="$t('Menu')" text-variant="info" right shadow v-model="showNavList" width="12rem" tablist>
      <b-card no-body class="mb-1" v-for="nav in navList" :key="nav.name">
        <b-button block role="tab" class="text-left btn-info" @click="goto(nav.router)">{{ $t(nav.name) }}</b-button>

        <!-- <b-collapse :id="'nav' + index" visible accordion="my-accordion" role="tabpanel">
          <div>
            <b-button variant="bg-white" block squaredd class="text-left">
              {{ child.name }}
            </b-button>
          </div>
        </b-collapse>-->
      </b-card>
    </b-sidebar>
  </div>
</template>

<script>
import i18n from "../i18n";
export default {
    data() {
        return {
            showContactList: false,
            showNavList: false,
            language: i18n.locale,
            navList: [
                {
                    name: "Vehicles",
                    router: "/cars/all",
                },
                {
                    name: "Location",
                    router: "/auckland",
                },
                {
                    name: "HotDeals",
                    router: "/hotdeals",
                },
                {
                    name: "Support",
                    router: "/support",
                },
                {
                    name: "Contact",
                    router: "/contact",
                },
            ],
        };
    },
    methods: {
        goto(route, nav) {
            this.$router.push(route);
        },
        goHome() {
            this.$router.push("/");
        },
        goInfo() {
            this.$router.push("/shuttle");
        },
        changeLang(val) {
            this.language = val;
            localStorage.setItem("locale", val);
            this.$i18n.locale = val;
        },
    },
    mounted() {
      this.language = localStorage.getItem("locale") || "en";
      this.$i18n.locale = this.language;
    },
};
</script>

<style>
.logo {
    background-image: url("../assets/Logo.jpg");
    height: 5rem;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-origin: content-box;
    background-position: -1.3vw 50%;
}
@media screen and (max-width: 780px) {
    .logo {
        height: 4rem;
        background-size: 130% auto;
        background-position: center;
    }
}
@media screen and (max-width: 480px) {
    .logo {
        height: 2.5rem;
    }
}
.navbar {
    position: relative;
}

.navbar > .icon .dianhua {
    margin: 0 0.2rem;
}
.navbar > .icon .tt-icon {
    font-size: 30px;
}

.contact {
    font-weight: bold;
    font-size: 20px;
    margin-top: -1rem;
}
.contact > span {
    margin-right: 1rem;
}
.soft-blue {
    color: #2ea9df;
}
.hoverpointer-nav:hover {
    cursor: pointer;
    color: #1e7094 !important;
}
</style>
