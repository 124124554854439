<template>
  <div>
    <div class="bg-img">
      <div class="middle position-relative">
        <b-container fluid class="position-absolute find-bar-height">
          <b-row align-h="center">
            <b-card no-body class="d-flex flex-row flex-wrap col-10 pb-3 pt-1 px-0" style="border-radius:0;border-top-left-radius: 0.25rem;">
              <b-col class="text-info font-weight-bold col-12 col-xl-6 my-xl-2 my-1 h4">{{ $t("Pickup") }}</b-col>
              <b-col class="text-info font-weight-bold col-12 col-xl-6 my-xl-2 my-1 order-xl-0 order-1 h4">{{ $t("Return") }}</b-col>
              <b-col class="col-xl-6 d-flex flex-row flex-wrap">
                <b-form-select :disabled="options.length == 1" size="sm" class="col-12 col-md-4" :options="options" v-model="order.pickUpLocation"></b-form-select>
                <b-form-datepicker
                  class="col-12 col-md-4"
                  size="sm"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="order.pDate"
                  :min="tomorrowDay"
                  locale="en-GB"
                  hide-header
                  placeholder="Select date"
                ></b-form-datepicker>
                <b-form-timepicker
                  class="col-12 col-md-4"
                  hide-header
                  minutes-step="30"
                  :hour12="false"
                  locale="en-GB"
                  reset-button
                  size="sm"
                  placeholder="Select time"
                  :show-seconds="false"
                  @context="onContext($event, 'pTime')"
                  v-model="order.pTime"
                ></b-form-timepicker>
              </b-col>
              <b-col class="col-xl-6 d-flex flex-row flex-wrap order-1 order-xl-0">
                <b-form-select :disabled="options.length == 1" size="sm" class="col-12 col-md-4" :options="options" v-model="order.dropOffLocation"></b-form-select>
                <b-form-datepicker
                  size="sm"
                  hide-header
                  class="col-12 col-md-4"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="order.rDate"
                  :min="order.pDate"
                  locale="en-GB"
                  placeholder="Select date"
                ></b-form-datepicker>
                <b-form-timepicker
                  hide-header
                  :hour12="false"
                  locale="en-GB"
                  minutes-step="30"
                  reset-button
                  size="sm"
                  class="col-12 col-md-4"
                  placeholder="Select time"
                  :show-seconds="false"
                  v-model="order.rTime"
                  @context="onContext($event, 'rTime')"
                ></b-form-timepicker>
              </b-col>
            </b-card>
            <b-button
              id="tooltip-target-1"
              @click="findCar"
              size="sm"
              squared
              variant="info"
              type="submit"
              class="col-10 col-md-1 mt-2 mt-md-0"
              style="border-top-right-radius: 0.25rem !important;"
            >
              <i class="font-weight-bold tt-icon car h3-md h5 px-3 px-md-0" />
              <span class="font-weight-bold h6-md d-md-block">{{ $t("Findmycar") }}</span>
            </b-button>
            <b-button @click="goto('/manage')" size="sm" squared variant="info" class="col-10 mt-2 d-md-none" style="border-top-right-radius: 0.25rem !important;">
              <i class="font-weight-bold tt-icon form h5 px-3" />
              <span class="font-weight-bold">{{ $t("Managemybooking") }}</span>
            </b-button>
          </b-row>
          <b-row align-h="center" class="d-none d-md-flex">
            <b-col sm="10" md="11" style="background-color:#e9ecef;" class="rounded-bottom">
              <b-row no-gutters align-h="between" class="py-1">
                <b-col class="text-center">
                  <i class="tt-icon tick text-info font-weight-bold"></i>
                  {{ $t("Freecancellation") }}
                </b-col>
                <b-col class="text-center">
                  <i class="tt-icon tick text-info font-weight-bold"></i>
                  {{ $t("Freeairportshuttle") }}
                </b-col>
                <b-col class="text-center">
                  <i class="tt-icon tick text-info font-weight-bold"></i>
                  {{ $t("Bestpriceguarantee") }}
                </b-col>
                <b-col class="text-center">
                  <i class="tt-icon tick text-info font-weight-bold"></i>
                  {{ $t("Nohiddenfees") }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div class="toolbar">
      <b-alert show variant="primary" class="fixed-bottom d-md-none mb-0 p-0 text-center rounded-0 font-weight-bold">
        Open Hours: Mon to Sun 9am-6pm
        <b-row>
          <b-col>
            NZ:
            <a href="tel:0800 668 628" class="mr-1">0800 668 628</a>
            AU:
            <a href="tel:1800 861 966">1800 861 966</a>
          </b-col>
        </b-row>
      </b-alert>
      <!-- <b-button-toolbar class="fixed-bottom d-md-none">
        <b-button-group size="md" style="width: 100%;">
          <b-button @click="goto('/manage')" variant="warning">
            <i class="font-weight-bold tt-icon form"></i>
            <span class="font-weight-bold" style="display: block;">Manage</span>
          </b-button>
          <b-button @click="goto('/pickup')" variant="warning">
            <i class="font-weight-bold tt-icon TravelPlaces"></i>
            <span class="font-weight-bold" style="display: block;">
              <span class="d-block">Pick up</span>
            </span>
          </b-button>
          <b-button @click="goto('/onroad')" variant="warning">
            <i class="font-weight-bold tt-icon chuchehuanche"></i>
            <span class="font-weight-bold" style="display: block;">
              <span class="d-block">On road</span>
            </span>
          </b-button>
          <b-button @click="goto('/dropoff')" variant="warning">
            <i class="font-weight-bold tt-icon tiche"></i>
            <span class="font-weight-bold" style="display: block;">Return</span>
          </b-button>
        </b-button-group>
      </b-button-toolbar>-->
      <!-- <b-button-toolbar class="d-none d-md-block">
        <b-button-group size="md" style="width: 100%;">
          <b-button @click="goto('/manage')" variant="warning">
            <i class="font-weight-bold tt-icon form h1"></i>
            <span class="font-weight-bold" style="display: block;">Manage My Booking</span>
          </b-button>
          <b-button @click="goto('/pickup')" variant="warning">
            <i class="font-weight-bold tt-icon TravelPlaces h1"></i>
            <span class="font-weight-bold" style="display: block;">Collect My Car</span>
          </b-button>
          <b-button @click="goto('/onroad')" variant="warning">
            <i class="font-weight-bold tt-icon chuchehuanche h1"></i>
            <span class="font-weight-bold" style="display: block;">On My Journey</span>
          </b-button>
          <b-button @click="goto('/dropoff')" variant="warning">
            <i class="font-weight-bold tt-icon tiche h1"></i>
            <span class="font-weight-bold" style="display: block;">Return My Car</span>
          </b-button>
        </b-button-group>
      </b-button-toolbar>-->
    </div>

    <div class="middle">
      <h3 class="text-center pt-5 fredoka">Best Car Rental in New Zealand</h3>
      <div class="bg-light-home mt-5">
        <h2 class="text-center pt-2 fredoka">{{ $t("HotDeals") }}</h2>
        <b-row>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/hotdeals',1)">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/other/deal-one-home.jpg" alt="Best Car Rental New Zealand" class="rounded-0"></b-card-img>
                </b-col>
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">9.5折优惠码</span>
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/hotdeals',2)">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/other/deal-two-home.jpg" alt="Cheap Weekly Car Hire" class="rounded-0"></b-card-img>
                </b-col>
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">周租9折优惠码</span>
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/hotdeals',3)">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/other/deal-three-home.jpg" alt="Cheap Monthly Car Hire" class="rounded-0"></b-card-img>
                </b-col>
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">月租8.5折优惠码</span>
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/hotdeals',4)">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/other/deal-four-home.jpg" alt="Best Car Rental in New Zealand " class="rounded-0"></b-card-img>
                </b-col>
                <!-- <b-col cols="12">
                  <b-card-body title="20% Cashback on base rates">
                    <b-card-text>
                      <p>We expect your feedback.</p>
                      <p>Post your reviews on Google to claim 20% cashback from your rental base rates after returned the vehicles. (Click for more details)</p>
                    </b-card-text>
                  </b-card-body>
                </b-col>-->
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">老客户8折优惠码</span>
          </b-col>
        </b-row>
      </div>

      <div class="bg-light-home mt-5">
        <h2 class="text-center pt-2 fredoka">{{ $t("Support") }}</h2>
        <b-row>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/manage')">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/support/manage.png" alt="Image" class="rounded-0"></b-card-img>
                </b-col>
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">订单管理</span>
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/shuttle')">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/support/shuttle-info.jpg" alt="Image" class="rounded-0"></b-card-img>
                </b-col>
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">免费机场接送</span>
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/bond')">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/support/bond-info.jpg" alt="Image" class="rounded-0"></b-card-img>
                </b-col>
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">信用卡&押金</span>
          </b-col>
          <b-col cols="12" sm="6" xl="3">
            <b-card no-body class="overflow-hidden" @click="goto('/insurance')">
              <b-row class="hoverpointer hover-deal">
                <b-col cols="12">
                  <b-card-img src="@/assets/support/insurance-info.jpg" alt="Image" class="rounded-0"></b-card-img>
                </b-col>
              </b-row>
            </b-card>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">保险种类&覆盖范围</span>
          </b-col>
        </b-row>
      </div>

      <!-- car gallary -->
      <div class="bg-light-home px-2 mt-5">
        <h2 class="text-center pt-2 fredoka">{{ $t("HotVehicles") }}</h2>
        <b-row>
          <b-col cols="6" sm="2" class="p-2 hover-no-padding hoverpointer" v-for=" v in hotVechicles" :key="v.carId">
            <b-img @click="gotoOption(v.carId)" thumbnail fluid :src="v.src" :alt="v.alt"></b-img>
          </b-col>
        </b-row>
      </div>

      <b-row id="id-reviews" class="mt-5 reviews-bg-img rounded" align-v="center" align-h="between" no-gutters>
        <b-carousel class="col-12 col-lg-6" :interval="5000" :controls="false" indicators img-width="1024" img-height="300" style="text-shadow: 1px 1px 1px #666;">
          <!-- Slide with blank fluid image to maintain slide aspect ratio -->
          <b-carousel-slide img-blank>
            <h4>Excellent communication to make pickup and drop off quick and easy. The car was as promised, new and fantastic. Prices also great.</h4>
            <div>By T Hickling</div>
          </b-carousel-slide>
          <b-carousel-slide img-blank>
            <h4>They have a good fleet to choose from and their staff are extremely helpful and willing to make amends to bookings with ease.</h4>
            <div class="text-white">By Jeanine Hepworth</div>
          </b-carousel-slide>
          <b-carousel-slide img-blank>
            <h4>They surely do have top cars that had everything I needed. Big thanks for going above and beyond and you made the whole process easy and quick.</h4>
            <div>By Samantha Adams</div>
          </b-carousel-slide>
        </b-carousel>
        <b-row class="text-white font-weight-bold mt-lg-2 col-12 col-sm-12 col-lg-6 pl-3 pl-lg-0 pb-5 pb-lg-0" align-v="center" align-h="between" no-gutters>
          <b-col>
            <div class="circle-review text-center bg-warning">
              <p class="h3">Google</p>
              <div>
                <b-form-rating value="4.5" size="lg" readonly no-border color="white" class="rating p-0"></b-form-rating>
              </div>
              <p>4.8/5</p>
            </div>
          </b-col>
          <b-col>
            <div class="circle-review text-center bg-info">
              <p class="h3">Trip.com</p>
              <div>
                <b-form-rating value="4.5" size="lg" readonly no-border color="white" class="rating p-0"></b-form-rating>
              </div>
              <p>4.8/5</p>
            </div>
          </b-col>
          <b-col>
            <div class="circle-review text-center bg-primary">
              <p class="h3">QEEQ.com</p>
              <div>
                <b-form-rating value="4.5" size="lg" readonly no-border color="white" class="rating p-0"></b-form-rating>
              </div>
              <p>9.7/10</p>
            </div>
          </b-col>
        </b-row>
      </b-row>
    </div>
    <b-modal id="pay-modal" @ok="payOk" ok-only hide-header centered button-size="sm">
      <div class="text-center" v-if="$i18n.locale === 'en'">
        No prepayment needed when booking.
        <br />
        <br />Credit card/debit card must be in the name of driver and with Visa/Mastercard logo.
        <br />
        <br />Card only show "Cardholder" will
        <b>NOT</b> accept.
      </div>
      <div class="text-center" v-else>
        预订时无需预付费用。
        <br />
        <br />信用卡/借记卡必须是司机名下，并有Visa/Mastercard的标志。
        <br />
        <br />
        <b>不接受</b>
        只显示“持卡人”的银行卡。
      </div>
    </b-modal>
  </div>
</template>

<script>
import utility from "../utils/utility";

export default {
    metaInfo: {
        title: "Best Car Rental | Cheap Car Hire New Zealand | Top Rental",
        meta: [
            {
                name: "Keywords",
                content: "car rental nz,rental cars nz,best car rental new zealand,cheap car hire new Zealand",
            },
            {
                name: "Description",
                content:
                    "Book the best value car rental in New Zealand at Top Rental. Our rental vehicles are assessed by New Zealand certified agencies. Hire Cheap Car. Get Hot Deal!",
            },
        ],
    },
    name: "Home",
    data() {
        let tomorrowDay = this.$dayjs(new Date()).add(24, "hour").format("YYYY-MM-DD");
        return {
            tomorrowDay: tomorrowDay,
            mainProps: { blank: true, width: 75, height: 75, class: "m1" },
            options: [{ value: "Auckland Airport", text: "Auckland Airport" }],
            order: {
                pickUpLocation: "Auckland Airport",
                dropOffLocation: "Auckland Airport",
                pDate: this.$dayjs(new Date()).add(2, "day").format("YYYY-MM-DD"),
                rDate: this.$dayjs(new Date()).add(5, "day").format("YYYY-MM-DD"),
                pTime: "12:00",
                rTime: "12:00",
            },
            hotVechicles: [
                { carId: 14, src: "/img/cars/Aqua-2015-2017.jpg", alt: "Aqua-2015-2017" },
                { carId: 4, src: "/img/cars/Corolla-2018-2020.jpg", alt: "Corolla-2018-2020" },
                { carId: 10, src: "/img/cars/Camry-2018-2020.jpg", alt: "Camry-2016-2017" },
                { carId: 2, src: "/img/cars/Rav4-2019.png", alt: "Rav4-2019" },
                { carId: 13, src: "/img/cars/Rav4-Hybrid.jpg", alt: "Rav4-Hybrid" },
                { carId: 12, src: "/img/cars/Highlander-2021.png", alt: "Highlander-2021" },
            ],
        };
    },
    methods: {
        ...utility,
        onContext({ hours, minutes }, field) {
            try {
                if (hours === null) {
                    throw "12:00";
                }
                if (hours < 9) {
                    throw "09:00";
                } else if (hours > 18 || (hours === 18 && minutes !== 0)) {
                    throw "18:00";
                }
            } catch (time) {
                this.order[field] = time;
            }
        },
        gotoOption(carId) {
            sessionStorage.setItem("order", JSON.stringify({ carId: carId }));
            this.$router.push("options");
        },

        findCar() {
            let error = this.dateRangeError(this.order);
            if (error) {
                this.$bvModal.msgBoxOk(error, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                return;
            }
            this.$bvModal.show("pay-modal");
        },
        payOk() {
            sessionStorage.setItem("order", JSON.stringify(this.order));
            //this.$bvModal.hide("pay-modal");
            this.$router.push("booking");
        },
        goto(route, pos) {
            this.$router.push({ path: route, query: { pos: pos } });
        },
    },
    computed: {
        clientWidth() {
            return document.querySelector("html").clientWidth;
        },
        minScreen() {
            return this.clientWidth < 1280;
        },
    },
    watch: {},
};
</script>

<style scoped>
.rating,
.form-control:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

.middle {
    width: 80rem;
    margin: 0 auto 0;
    max-width: 100%;
    height: 100%;
}
.toolbar {
    width: 80rem;
    margin: 0 auto 0;
    max-width: 100%;
}
.bg-img {
    max-width: 100%;
    height: 35rem;
    background-image: url("../assets/bg-home.jpg");
    background-size: cover;
    background-origin: content-box;
    background-position: center;
}
.find-bar-height {
    bottom: 8%;
}
.hover-no-padding:hover {
    padding: 0.25rem !important;
}
.circle-review {
    display: inline-block;
    width: 10rem;
    height: 10rem;
    border-radius: 100px;
    padding: 1.4rem 3px 0;
}
.reviews-bg-img {
    margin-bottom: 3rem;
    background-image: url("../assets/other/bg-review.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center;
}

@media screen and (min-width: 1200px) {
    .circle-review {
        margin-left: 2rem;
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .circle-review {
        margin-left: 0.5rem;
    }
    .reviews-bg-img {
        height: 12rem;
    }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
    .circle-review {
        margin-left: 2.4rem;
        margin-bottom: -5rem;
    }
}
@media screen and (min-width: 421px) and (max-width: 521px) {
    .circle-review {
        transform: scale(0.8);
        margin-left: -1.3rem;
        margin-bottom: -5rem;
        padding: 1.5rem 0 0;
    }
    .carousel-item {
        min-height: 180px;
    }
}
@media screen and (max-width: 420px) {
    .circle-review {
        transform: scale(0.6);
        margin-left: -1.8rem;
        margin-bottom: -5rem;
        padding: 1.5rem 0 0;
    }
    .carousel-item {
        min-height: 180px;
    }
    .carousel-caption > h4 {
        font-size: 1.2rem;
    }
}
/* @media screen and (max-width: 1280px) {
    .circle-review {
        transform: translateX(-10%) scale(0.76);
    }
    .carousel {
        font-size: 0.85em;
    }
    .circle-review {
        transform: scale(0.8);
    }
}
@media screen and (max-width: 769px) and (min-width: 721px) {
    .reviews-bg-img {
        height: 20rem;
    }
    .carousel {
        font-size: 0.85em;
        margin-top: -100px;
    }
    .circle-review {
        transform: translate(0%, -20%) scale(0.7);
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 720px) {
    .bg-img {
        height: 30.85rem;
    }
    .find-bar-height {
        bottom: 20%;
    }

    .circle-review {
        width: 30vw;
        height: 30vw;
        border-radius: 15vw;
        font-size: 4vw;
        padding: 18px 0px 0;
        margin-bottom: 3rem;
    }
    .circle-review:first-child {
        text-align: start;
    }
    .circle-review .h3 {
        font-size: 5vw;
    }
    .circle-review p {
        line-height: 4vw;
    }
}
@media screen and (max-width: 719px) and (min-width: 430px) {
    .reviews-bg-img {
        margin-top: 0px;
        height: 21rem;
    }
    .circle-review {
        width: 150px;
        height: 150px;
        border-radius: 100px;
        font-size: 1rem;
        padding: 1.6rem 0px 0;
        margin-bottom: 4rem;
    }
    .circle-review .h3 {
        font-size: 1.4rem;
    }
}
@media screen and (max-width: 376px) and (min-width: 429px) {
    .circle-review {
        width: 150px;
        height: 150px;
        border-radius: 100px;
        font-size: 1rem;
        padding: 28px 0px 0px;
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 375px) {
    .reviews-bg-img {
        margin-top: 0px;
        height: 40rem;
    }
    .circle-review {
        width: 5rem;
        height: 5rem;
        border-radius: 100px;
        font-size: 1rem;
        padding: 1rem 0px 0px;
        margin-bottom: 1rem;
    }
} */
.hover-deal {
    transition: 0.5s;
}
.hover-deal:hover {
    transform: scale(1.02);
}
.bg-light-home {
    background-color: rgb(245, 244, 244);
}
</style>
