import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        meta: { title: "Best Car Rental | Cheap Car Hire New Zealand | Top Rental" },
        component: Home,
    },
    {
        path: "/cars/:size",
        name: "Cars",
        meta: { title: "Hybrid and Toyota RAV4 Rental Car | Vehicle Rental Auckland NZ" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/cars.vue"),
    },
    {
        path: "/cars/all",
        name: "Cars",
        meta: { title: "Hybrid and Toyota RAV4 Rental Car | Vehicle Rental Auckland NZ" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/cars.vue"),
    },
    {
        path: "/booking",
        name: "Booking",
        meta: { title: "Booking" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/booking.vue"),
    },
    {
        path: "/auckland",
        name: "Auckland",
        meta: { title: "Cheap Car Hire | Best Car Rental  Mangere Auckland" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/auckland.vue"),
    },
    {
        path: "/hotdeals",
        name: "Hotdeals",
        meta: { title: "Car Rental Deals Auckland NZ| Top Rental" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/hotdeals.vue"),
    },
    {
        path: "/driving",
        name: "Driving",
        meta: { title: "Drive Safe" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/driving.vue"),
    },
    {
        path: "/questions",
        name: "questions",
        meta: { title: "Frequently Asked Questions" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/questions.vue"),
    },
    {
        path: "/terms",
        name: "terms",
        meta: { title: "Terms & Conditions" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/terms.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        meta: { title: "Contact Us" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/contact.vue"),
    },
    {
        path: "/support",
        name: "supoort",
        meta: { title: "Support" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/support.vue"),
    },
    {
        path: "/manage",
        name: "manage",
        meta: { title: "Manage your reservations" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/manage.vue"),
    },
    {
        path: "/dropoff",
        name: "dropoff",
        meta: { title: "Auckland Airport Drop Off - Car Rental | Top Rental" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/dropoff.vue"),
    },
    {
        path: "/pickup",
        name: "pickup",
        meta: { title: "Auckland Airport Pick Up - Car Rental | Top Rental" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/pickup.vue"),
    },
    {
        path: "/onroad",
        name: "onroad",
        meta: { title: "On your journey" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/onroad.vue"),
    },
    {
        path: "/options",
        name: "options",
        meta: { title: "Booking Options" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/options.vue"),
    },
    {
        path: "/details",
        name: "details",
        meta: { title: "Your Details" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/details.vue"),
    },
    {
        path: "/orderReview",
        name: "orderReview",
        meta: { title: "Your order" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/order-review.vue"),
    },
    {
        path: "/login",
        name: "login",
        meta: { title: "Admin Login" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/admin/login.vue"),
    },
    {
        path: "/contract",
        name: "contract",
        meta: { title: "Contract" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/contract.vue"),
    },
    {
        path: "/insurance",
        name: "insurance",
        meta: { title: "Rental Car Insurance New Zealand| Top Rental" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/insurance.vue"),
    },
    {
        path: "/bond",
        name: "bond",
        meta: { title: "Info of bond" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/bond.vue"),
    },
    {
        path: "/shuttle",
        name: "shuttle",
        meta: { title: "Shuttle" },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/shuttle.vue"),
    },
    {
        path: "/admin",
        name: "admin",
        meta: { title: "Cars Admin", admin: true },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/admin/admin.vue"),
    },
    {
        path: "/admin/order",
        name: "admin/order",
        meta: { title: "Order Admin", admin: true },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/admin/order.vue"),
    },
    {
        path: "/admin/car",
        name: "admin/car",
        meta: { title: "Car Admin", admin: true },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/admin/car.vue"),
    },
    {
        path: "/admin/price",
        name: "admin/price",
        meta: { title: "Price Admin", admin: true },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/admin/price.vue"),
    },
    {
        path: "/admin/stop-sale",
        name: "admin/stop-sale",
        meta: { title: "Stop Sale", admin: true },
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/admin/sold-out.vue"
            ),
    },
    {
        path: "/admin/shutdown",
        name: "admin/shutdown",
        meta: { title: "Shutdown", admin: true },
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/admin/shutdown.vue"
            ),
    },
    {
        path: "*",
        name: "error",
        component: Home,
    },
];

const router = new VueRouter({
    routes: routes,
    mode: "history",
    base: "page"
});

export default router;
