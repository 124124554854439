import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

let initLanguage = localStorage.getItem("locale");
let defaultLanguage = navigator.language;
let language = "en";

export const i18n = new VueI18n({
  locale: language,
  messages: {
    zh: require("./lang/zh"), 
    en: require("./lang/en")
  }
});

export default i18n;