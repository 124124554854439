/* 
{{ $t("") }}
export const  = "";
*/
export const Vehicles = "热门车型";
export const HotDeals = "热门促销";
export const OpenHours ="营业时间";
export const Importantupdate = "重要提示：国际航站楼接驳位置指引，请点击";
export const Here = "这里";
export const AboutUs = "关于我们";
export const ShuttleBus= "免费接驳车";
export const TermsConditions = "条款";
export const Managebooking = "订单管理";
export const Collectyourcar = "提车";
export const Returnyourcar = "还车";
export const Contactus = "联系我们";
export const Contact = "联系我们";
export const Location = "门店地址";
export const Support = "服务支持";
export const Partners = "合作伙伴";
export const HotVehicles = "热门车型";
export const Pickup = "提车";
export const Return = "还车";
export const Freecancellation = "免费取消";
export const Freeairportshuttle = "免费机场接送";
export const Bestpriceguarantee = "最优价格";
export const Nohiddenfees = "无隐形消费";
export const Findmycar = "搜索";
export const Managemybooking = "管理订单";
export const ContactDetails = "联系详情";
export const OpeningHours = "营业时间";
export const MoreOptions = "更多方式";
export const NZ = "新西兰";
export const AU = "澳大利亚";
export const Email = "邮箱";
export const Address = "地址";
export const Monday = "周一";
export const Sunday = "周日";
export const To = "到";
export const HotDealswith = "热门促销";
export const TopRental = "途行租车"; 
export const Bookyourrental = "使用下面与您需求相对应的促销代码预订车辆，即可享受折扣。";
export const Ifneedhelp = "如果需要帮助";
export const Newtous = "新客户";
export const Warmwelcometo = "热烈欢迎我们的新客户。即可享受9.5折优惠，输入此优惠码";
export const Forallvehicle = "。适用于所有车型，租期三天及以上。";
export const Rentforaweek = "租期一周及以上";
export const Usethepromo = "使用此优惠码";
export const Andget10 = "即可享受9折优惠，适用于所有车型。";
export const Rentforamonth = "租期一个月及以上（28天及以上）";
export const Andget15 = "输入此优惠码，即可享受8.5折优惠， 适用于所有车型。";
export const Forourlovely = "回头客独享特惠";
export const Contactuswith = "联系客服并提供历史订单号，即可享受8折优惠！ 适用于所有车型，租期三天及以上。";
export const WeChat = "微信";
export const Supportand = "支持和服务 - ";
export const Hereisall = "在这里您能找到所有旅途中需要的信息";
export const KiaOraand = "欢迎来到途行租车";
export const Travelingisnever = "旅行中难免会有一些小插曲，这也正是我们在您身边的原因：保证您享受安全和舒心的自驾是我们";
export const Priority = "重要的事情。";
export const Plus = "还有";
export const Fantasticcarrental = "完美的租车体验";
export const Competitiveprice = "具有竞争力的价格";
export const OurCars = "我们的车队";
export const Allourrental = "为了保证您的出行安全，我们所有的车辆都是经过新西兰政府官方授权点检测，并且定期保养维护。";
export const Alsoweprovide = "为了满足广大旅客不同的需求，我们也准备了各种车型供您选择。无论是经济实惠的家庭旅行舒适小车，还是给多人出行使用的商务车，我们皆能满足您的需求。";
export const OurTeam = "我们的团队";
export const Ourprofessionaland = "我们专业并热情的团队，会尽全力让确保您的整个租车体验简单，快捷。";
export const Letusto = "让我们为您旅行中的交通开销降至最少！";
export const Usetheonline = "如果有任何疑问，请填写下方表格给我们留言，我们会在24小时内与您联系。";
export const Submit = "提交";
export const Moredetails = "详细信息";
export const MonSun = "周一至周天：9am-6pm";
export const Top = "最";
export const Weprovidefree = "每天早9点到晚6点，我们提供免费的机场接驳服务。";
export const Calluson = "请拨打";
export const Or = "或者";
export const Whenyouare = "，当您到达指定接驳区域后请耐心等待10-15分钟。";
export const ForAucklandInternational = "奥克兰国际航站楼接驳区：";
export const Pleaseexitthrough = "请从4号门出来，然后穿过人行横道，进入带有黑白色箭头标识Car Rental的临时步行通道。请跟着路牌指引 'Car rental/shuttle'（租车服务）, 便会到达指定接驳区域。请参考下图中的黄色箭头：";
export const ForAucklandDomestic = "奥克兰国内航站楼接驳区:";
export const Pleasewaitoutside = "请从8号门出来，也就是捷星航空（Jetstar）柜台正对面，我们的大巴会停在您正前方，红线标记的位置。";
export const ReservationNumber = "订单号";
export const LastName = "姓";
export const Search = "搜索";
export const ManageYourBooking = "订单管理";
export const FindMyBooking = "搜索订单";
export const Booking = "订单";
export const Yourbookinghas = "您的订单已经";
export const Contactusif = "。如果需要帮助，请联系我们。";
export const Yourdetailsare = "您的信息如下。";
export const Name = "名字";
export const DOB = "生日";
export const Phone = "电话";
export const Flight = "航班";
export const Message = "留言";
export const Day = "/天";
export const For = "";
export const Days = "天:";
export const BookingDetails = "订单详情";
export const PickUp = "提车";
export const CarRentalFee = "租金费用";
export const Discount = "折扣";
export const TOTAL = "总价";
export const DaysGSTincluded = "天（含税）";
export const CANCEL = "取消";
export const NissanTiidaHatch = "尼桑骐达两厢";
export const ToyotaCorollaHatch = "丰田卡罗拉两厢";
export const NissanXtrail = "日产奇骏";
export const ToyotaRav4 = "丰田荣放";
export const ToyotaHighlander = "丰田汉兰达";
export const NissanPathfinder = "尼桑探路者";
export const ToyotaCamry = "丰田凯美瑞";
export const ToyotaHighlanderHybrid = "丰田汉兰达混动";
export const ToyotaRav4Hybrid = "丰田荣放混动";
export const ToyotaAquaHybrid = "丰田Aqua混动";
export const ToyotaCorollaCross = "丰田锐放混动";
export const NissanNote = "尼桑Note";
export const Find = "搜索";
export const Select = "选择";
export const SoldOut = "售罄";
export const Pickyourcar = "挑选您的车辆";
export const Insuranceextras = "保险 & 配件";
export const Driversdetails = "主驾信息";
export const SecondDriver = "副驾";
export const FREE = "免费";
export const Max140 = "做多140字";
export const Iagreeto = "我同意";
export const BookingSummary = "订单总结";
export const Howtopay = "如何付款";
export const Back = "返回";
export const BookNow = "即刻预定";
export const BookingReceived = "订单已收到";
export const Yourbookinghasbeenreceived = "您的预订已收到，我们会尽快与您确认订单状态。取车之前不需要付款。";
export const Youwillreceive = "您将在接下来的24小时内收到一封确认邮件。请留意您的广告或垃圾邮件文件夹，以免邮件被错误分类。";
export const BackHome = "返回首页";
export const Continue = "下一步";
export const Soldout = "售罄";
export const Promocode = "优惠码";
export const Apply = "使用";
export const Extraoptions = "额外配件";
export const InsuranceComparison = "保险对比";
export const SelectYourInsurance = "请选择您所需保险";
export const Vehicleyouchosen = "以下是您所选车型";
export const Free = "免费";
export const FirstName = "名";
export const EnterFirstName = "请输入名字";
export const EnterLastName = "请输入姓氏";
export const EmailAddress = "邮箱";
export const EnterEmail = "请输入邮箱";
export const PhoneNumber = "电话";
export const EnterPhoneNumber = "请输入电话";
export const FlightNumber = "航班号（可选）";
export const EnterFlightNumber = "请输入航班号";
export const DriverBirthdate = "驾驶员出生日期";
export const EnterYourMessage = "请输入留言";
export const FullName = "姓名";
export const EnterFullName = "请输入姓名";
export const submitted = "提交";
export const confirmed = "确认";
export const Basic = "基本险";
export const basic = "基本险";
export const plus = "偷盗险";
export const canceled = "取消";
export const premium = "全险";
export const PhoneHolder = "手机支架";
export const GPSNavigation = "GPS导航";
export const BoosterSeat = "增高垫";
export const BabySeat = "儿童座椅";
export const YoungDriverFee = "年轻司机附加费";
export const Protection = "偷盗险";
export const Premium = "全险";
export const Automatic = "自动挡";
export const orsimilar = "或同级车型";
export const Seats7 = "7座";
export const AutomaticHighBeam = "自动远光灯";
export const Bluetooth = "蓝牙";
export const ReversingCamera = "倒车影像";
export const CruiseControl = "定速巡航";
export const USBPort = "USB接口";
export const LaneDepartureAlert = "车道偏离提醒";
export const BlindSpotMirror = "盲点检测";
export const AdaptiveCruiseControl = "自适应巡航";
export const MostPopular = "最受欢迎车型";
export const EcoFriendly = "Eco低油耗";
export const UnlimitedKilometers = "不限公里数";
export const Rangeupto800kms = "最高续航800kms";
export const Rangeupto950kms = "最高续航950kms";
export const Y04YearsOld = "0 - 4年车龄";
export const Y03YearsOld = "0 - 3年车龄";
export const Y02YearsOld = "0 - 2年车龄";
export const Large7Seats = "宽敞7座";
export const AllNewHybridModel = "全新混动款";
export const opentime = "早9点-晚6点";
export const Menu = "菜单";
