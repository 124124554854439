import dayjs from "dayjs";

function orderStatus(staus) {
    let statusText = parseInt(staus);
    switch (statusText) {
        case 10:
            return "pending";
        case 20:
            return "confirmed";
        case 30:
            return "canceled";
        default:
            return "undefined";
    }
}

function carSize(staus) {
    let statusText = parseInt(staus);
    switch (statusText) {
        case 10:
            return "Small Car";
        case 20:
            return "Medium Car";
        case 30:
            return "Full Size SUVs";
        case 40:
            return "Group Vehicles";
        default:
            return "Unknown";
    }
}

function moneyLocaelString(num) {
    return parseFloat(
        parseFloat(num || 0)
            .toFixed(2)
            .toString()
    ).toLocaleString();
}

function dateFormat(date) {
    return dayjs(date).format("DD/MM/YYYY");
}

function dateTimeFormat(date) {
    return dayjs(date).format("DD/MM/YYYY HH:mm");
}

export default {
    orderStatus,
    carSize,
    moneyLocaelString,
    dateTimeFormat,
    dateFormat
};
