import dayjs from "dayjs";
import i18n from "vue-i18n";

function deconstructDate(order) {
    let { pDate, pTime, rDate, rTime } = order;
    let pickUpTime = pDate + " " + pTime;
    let dropOffTime = rDate + " " + rTime;
    return { pickUpTime: pickUpTime, dropOffTime: dropOffTime };
}

function dateRange(pickUpTime, dropOffTime) {
    return dayjs(dropOffTime).diff(dayjs(pickUpTime), "hour");
}

function dateValid(pickUpTime, dropOffTime) {
    if (
        !!pickUpTime &&
        !!dropOffTime &&
        dayjs(pickUpTime, ["YYYY-MM-DD HH:mm", "DD/MM/YYYY HH:mm"]).isValid() &&
        dayjs(dropOffTime, ["YYYY-MM-DD HH:mm", "DD/MM/YYYY HH:mm"]).isValid()
    ) {
        return false;
    } else {
        if(i18n.locale == "en"){
            return "The date is invalid!";
        }else{
            return "日期无效！";
        }
    }
}

function dateRangeLess48h(pickUpTime, dropOffTime) {
    let totalHours = dateRange(pickUpTime, dropOffTime);
    if (totalHours <= 24) {
        if(i18n.locale == "en"){
            return "Minimum 2 rental days.";
        }else{
            return "租车时长至少2天";
        }
    } else {
        return false;
    }
}

function dateStartLess24h(pickUpTime, dropOffTime) {
    let hours = dayjs(pickUpTime).diff(dayjs(new Date()), "hour");
    if (hours < 24) {
        if(i18n.locale == "en"){
            return "Pick up time is required at least 24 hours in advance.";
        }else{
            return "提车时间必须提前24小时。";
        }
    } else {
        return false;
    }
}

function dateRangeError(order) {
    let { pickUpTime, dropOffTime } = deconstructDate(order);
    let error1 = dateValid(pickUpTime, dropOffTime);
    let error2 = dateRangeLess48h(pickUpTime, dropOffTime);
    let error3 = dateStartLess24h(pickUpTime, dropOffTime);
    return error1 || error2 || error3;
}
function dateRangeForMoney(order) {
    let { pDate, pTime, rDate, rTime } = order;
    let { pickUpTime, dropOffTime } = deconstructDate(order);
    let lessOneDay = dayjs(pickUpTime).isBefore(dayjs(pDate + " " + rTime));
    if (lessOneDay) {
        pickUpTime = pDate + " 00:00:00";
        dropOffTime = rDate + " 23:59:59";
        return { pickUpTime: pickUpTime, dropOffTime: dropOffTime };
    } else {
        let pickUpEnd = dayjs(pDate + " 23:59:59");
        let dropOffStart = dayjs(dropOffTime).startOf("date");
        let pickUpRange = dayjs(pickUpEnd).diff(pickUpTime, "hour");
        let dropOffRange = dayjs(dropOffTime).diff(dropOffStart, "hour");
        if (pickUpRange <= dropOffRange) {
            pickUpTime = pDate + " 23:59:59";
            dropOffTime = rDate + " 23:59:59";
        } else {
            pickUpTime = pDate + " 00:00:00";
            dropOffTime = rDate + " 00:00:00";
        }
        return { pickUpTime: pickUpTime, dropOffTime: dropOffTime };
    }
}

function dateAsUsual(startDate, endDate) {
    return dayjs(startDate).isBefore(dayjs(endDate));
}

function removeBlank(text){
    if(!!text){
        return text.replace(/\s/g, '');
    }
}

export default {
    dateRangeError,
    dateRangeForMoney,
    deconstructDate,
    dateValid,
    dateAsUsual,
    removeBlank
};
